import { IoCalendarOutline } from 'react-icons/io5';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ProfileMenu } from '@/components/ProfileMenu';
import { useCurrentUserContext } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import {
  Flex,
  Link,
  Image,
  Grid,
  GridItem,
  type FlexProps,
  MenuItem,
  Icon,
} from '@/ui';
import logo1x from './assets/logo@1x.png';
import logo2x from './assets/logo@2x.png';
import { AuthControls } from './AuthControls';

const getFrom = (path: string): 'settings' | 'home' | 'shared' => {
  if (path === '/magic/settings') {
    return 'settings';
  }
  if (path.startsWith('/magic/p/') || path.startsWith('/magic/s/')) {
    return 'shared';
  }
  return 'home';
};

export const Banner = (props: FlexProps) => {
  const { currentUser, isLoading } = useCurrentUserContext();
  const { trackEvent } = useAnalytics();
  const { pathname } = useLocation();

  return (
    <Flex
      color="#766C8A"
      fontWeight="medium"
      px={{ base: 4, xl: 0 }}
      py="2.5"
      textAlign="center"
      {...props}
    >
      <Grid
        alignItems="center"
        gap="6"
        m="auto"
        maxW="container.lg"
        p="0"
        templateColumns="1fr 1fr"
        w="100%"
      >
        <GridItem>
          <Link href="/magic" target={'_blank'}>
            <Image
              alt="Agenda Hero"
              ml="-2" // account for whitespace inside the image asset
              srcSet={`${logo1x}, ${logo2x} 2x`}
            />
          </Link>
        </GridItem>

        {!isLoading && (
          <GridItem display="flex" justifyContent="flex-end">
            {currentUser ? (
              <ProfileMenu
                buttonProps={{ w: 'auto' }}
                menuProps={{ zIndex: 15 }}
                additionalMenuItems={
                  <MenuItem
                    as={RouterLink}
                    target="_blank"
                    to="/"
                    icon={
                      <Icon as={IoCalendarOutline} transform="scale(120%)" />
                    }
                    onClick={() =>
                      trackEvent('quick-create:click schedules', {
                        from: getFrom(pathname),
                      })
                    }
                  >
                    My schedules
                  </MenuItem>
                }
              />
            ) : (
              <AuthControls />
            )}
          </GridItem>
        )}
      </Grid>
    </Flex>
  );
};
