import { Button, type ButtonProps } from '@/ui';

export const ResetButton = (props: ButtonProps) => {
  return (
    <Button
      colorScheme="dark"
      fontSize="md"
      textDecor="underline"
      variant="link"
      {...props}
    >
      Clear and start over
    </Button>
  );
};
