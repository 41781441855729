import { Button, Flex, Text } from '@/ui';

type Props = {
  onCancel: () => void;
};

export const ScreenshotActive = ({ onCancel }: Props) => {
  return (
    <Flex
      align="center"
      borderColor="#bface1"
      borderRadius="xl"
      borderStyle="dashed"
      borderWidth="2px"
      color="#9180a3"
      direction="column"
      gap="2"
      justify="center"
      lineHeight="normal"
      p="4"
      textAlign="center"
    >
      <Text>⬅️</Text>
      Drag anywhere on the left
      <br />
      to take a screenshot!
      <Button
        color="currentcolor"
        colorScheme="dark"
        fontWeight="bold"
        textDecor="underline"
        variant="link"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Flex>
  );
};
