import { useTranslation } from 'react-i18next';
import { useAuthModal } from '@/pages/QuickCreate/components/AuthModal';
import { Button, Flex } from '@/ui';
import { Pill } from './Pill';

export const AuthControls = () => {
  const { open } = useAuthModal();
  const { t } = useTranslation('auth');

  return (
    <Flex align="center" gap="3">
      <Button color="#766C8A" variant="link" onClick={() => open()}>
        {t('sign_in')}
      </Button>
      <Pill color="#766C8A" onClick={() => open()}>
        {t('sign_up')}
      </Pill>
    </Flex>
  );
};
