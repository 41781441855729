import { Collapse } from '@/components/Collapse';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { CenteredContent } from '@/pages/QuickCreate/components/CenteredContent';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { Button, Flex } from '@/ui';
import { EntryList } from '../../components/EntryList';
import { LoadingCard } from '../../components/LoadingCard';
import { ResultsFeedbackForm } from '../../components/ResultsFeedbackForm';
import { ResetButton } from '../components/ResetButton';

type Props = {
  onCreateAnother: () => void;
};

export const PostMessageFlow = ({ onCreateAnother }: Props) => {
  const { state, createMutation } = useQuickCreateContext();

  return (
    <CenteredContent gap="6" pt="1">
      <Collapse in={createMutation.isError}>
        {createMutation.error && (
          <Flex flexDir="column">
            <QuickCreateErrorMessage error={createMutation.error} />

            <Button
              alignSelf="center"
              colorScheme="dark"
              fontSize="md"
              mt="4"
              textDecor="underline"
              variant="link"
              onClick={onCreateAnother}
            >
              Go back
            </Button>
          </Flex>
        )}
      </Collapse>

      {state === 'loading' ? (
        <LoadingCard />
      ) : state === 'edit' ? (
        <>
          <EntryList />
          <ResultsFeedbackForm />
          <ResetButton onClick={onCreateAnother} />
        </>
      ) : null}
    </CenteredContent>
  );
};
