import { Button, type ButtonProps, forwardRef } from '@/ui';

export const Pill = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      bg="white"
      borderColor="currentcolor"
      borderRadius="20px"
      borderWidth="1px"
      color="currentcolor"
      display="flex"
      fontSize="sm"
      gap="1"
      h="auto"
      lineHeight="normal"
      px="3"
      py="1.5"
      ref={ref}
      shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.10)"
      variant="unstyled"
      _hover={{
        textDecor: 'underline',
      }}
      {...props}
    />
  );
});
