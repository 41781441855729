import { Flex, Text, Divider as ChakraDivider, type FlexProps } from '@/ui';

export const Divider = (props: FlexProps) => {
  return (
    <Flex align="center" {...props}>
      <ChakraDivider borderColor="gray.500" />
      <Text
        color="#8674AA"
        fontSize="sm"
        fontWeight="medium"
        lineHeight="normal"
        px="4"
      >
        or
      </Text>
      <ChakraDivider borderColor="gray.500" />
    </Flex>
  );
};
