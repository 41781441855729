import { useEffect, useRef } from 'react';
import { logger } from '@/lib/logger';

export type Command =
  | '_ah_reset' // no longer used in v1.7.0 but kept for backwards compatibility
  | '_ah_set_prompt'
  | '_ah_pixel_ratio_response'
  | '_ah_auth_success'
  | '_ah_auth_failed'
  | '_ah_screenshot_cancel';

export type Callback = (message: string) => void;

/**
 * Invoke `callback` when a command is received from the extension.
 */
export const usePostMessageReceiver = (
  command: Command,
  callback: Callback
): void => {
  const stableCallback = useRef(callback);
  useEffect(() => {
    stableCallback.current = callback;
  });

  useEffect(() => {
    const handler = (event: MessageEvent<string>) => {
      if (typeof event.data === 'string' && event.data.startsWith(command)) {
        // some commands have a trailing '=' that we need to remove, like
        // `_ah_set_prompt=...` whereas others are a simple command like `_ah_reset`
        const message = event.data.substring(command.length).replace(/^=/, '');
        stableCallback.current(message);
        logger.info(`Received command from extension: ${command}`, {
          payload: message,
        });
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [command]);
};
