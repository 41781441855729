import { AiDisabledAlert } from '@/components/AiDisabledAlert';
import { AppLoader } from '@/components/AppLoader';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { AuthModal } from '../components/AuthModal';
import { CenteredContent } from '../components/CenteredContent';
import { Layout } from '../components/Layout';
import { useQuickCreateContext } from '../contexts';
import { Banner } from './components/Banner';
import { DefaultFlow } from './flows/DefaultFlow';
import { PostMessageFlow } from './flows/PostMessageFlow';
import { useAppReady } from './hooks/useAppReady';
import { useCreateOnPageLoad } from './hooks/useCreateOnPageLoad';
import { useFlow } from './hooks/useFlow';
import { useInvalidateProfileOnFocus } from './hooks/useInvalidateProfileOnFocus';
import { usePostMessageReceiver } from './hooks/usePostMessageReceiver';
import { useZoomFix } from './hooks/useZoomFix';

export const QuickCreateExtension = () => {
  const { resetAppState } = useQuickCreateContext();
  const { flow, setFlow } = useFlow();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );

  useAppReady();
  useCreateOnPageLoad();
  useZoomFix();
  useInvalidateProfileOnFocus();
  usePostMessageReceiver('_ah_reset', resetAppState);

  const handleCreateAnother = () => {
    resetAppState();
    setFlow('default');
  };

  return (
    <Layout layerStyle="magicBackground">
      <Banner mb="2" />

      {disableAllAiFeatures ? (
        <CenteredContent>
          <AiDisabledAlert />
        </CenteredContent>
      ) : flow === 'default' ? (
        <DefaultFlow onCreateAnother={handleCreateAnother} />
      ) : flow === 'post-message' ? (
        <PostMessageFlow onCreateAnother={handleCreateAnother} />
      ) : (
        <AppLoader />
      )}

      <AuthModal />
    </Layout>
  );
};
